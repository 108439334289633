.colorBadge {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
}

.middle {
    vertical-align: middle;
}

.buttonCol {
    padding: 0.3rem 0.5rem !important;
}

.row {
    td, th {
        background-color: var(--bs-gray-100);
    }
}

.emptyRow {
    height: 1rem;

    td {
        background-color: white !important;
    }
}
