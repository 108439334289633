main {
  min-height: calc(100vh - 160px);
}

.text-muted {
  color: var(--bs-gray-500) !important;
}

.form-control {
  border-radius: 4px;
  padding: 0.3rem 0.6rem;
}

.form-select {
  border-radius: 4px;
  padding: .3rem 2.25rem .3rem .6rem
}

.form-label {
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--bs-gray-100);
  --bs-table-bg-type: none;
}

.btn {
  border-radius: 4px;
}

.btn-primary {
  background-color: #6b21a8 !important;
  border-color: #6b21a8 !important;
}

.btn-primary:hover, .btn-primary:active {
  background-color: #581c8a !important;
  border-color: #581c8a !important;
}

.btn-tiny {
  height: 23px;
  padding: 3px 4px;
  line-height: 0.8;
  margin-top: -4px;
}
.btn-transparent {
  background: none;
  border: none;
}

.nav-pills .nav-link {
  padding: 5px 12px;
}
.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: #581c8a !important;

}

.modal.show {
  display: block;
}

.modal-dialog-scrollable {
  form {
    overflow-y: auto;
  }
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker {
  font-family: var(--bs-body-font-family);
}

.alert {
  padding: 12px 15px;
}