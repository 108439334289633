.event {
    display: inline-block;
    padding: 1px 7px;
    border-radius: 3px;
    color: white;
    font-size: 0.9rem;
    background-color: #6b21a8;
    cursor: pointer;
}

.eventRight {
    float: right;
}

.event > .eventLabel {
    display: inline-block;
}

.transparent {
    opacity: 0.4;
}

.deleteButton {
    height: 15px;
    padding: 2px 0;
    line-height: 0.8;
    margin-top: -3px;
    color: white;
    border: none;
    background: none;
}

.colorBadge {
    width: 16px;
    height: 16px;
    border-radius: 100%;
}

.middle {
    vertical-align: middle;
}

.buttonCol {
    padding: 0.3rem 0.5rem !important;
}
