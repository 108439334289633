.eventsCalendarTable tr td:first-child {
    padding-left: 10px;
    padding-right: 0;
    width: 1%;
}

.eventsCalendarTable tr td:nth-child(2) {
    width: 10%;
    text-align: right;
}

.eventsCalendarTable tr td:nth-child(3) {
    padding-left: 60px;
    width: 70%
}

.eventsCalendarTable tr th {
    padding: 30px 8px 6px 8px;
    background-color: white !important;
}
.eventsCalendarTable tr td {
    padding: 4px 6px;
}

@media (max-width: 1199.98px) {
    .eventsCalendarTable tr td:first-child {
        width: 5%;
    }

    .eventsCalendarTable tr td:nth-child(2) {
        width: 20%;
    }

    .eventsCalendarTable tr td:nth-child(3) {
        padding-left: 40px;
        width: 70%
    }
}

@media (max-width: 768px) {
    .eventsCalendarTable tr td:first-child {
        width: 1%;
    }

    .eventsCalendarTable tr td:nth-child(2) {
        width: 38%;
    }

    .eventsCalendarTable tr td:nth-child(3) {
        padding-left: 20px;
        width: 70%
    }
}