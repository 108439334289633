.colorBadge {
    width: 16px;
    height: 16px;
    border-radius: 100%;
}

.middle {
    vertical-align: middle;
}

.buttonCol {
    padding: 0.3rem 0.5rem !important;
}
